import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from '@components/Link';
import React, { useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Trans, useTranslation } from 'react-i18next';
import emojiMonocle from '../../../../static/img/emojis/face-with-monocle.png';
import emojiDoctor from '../../../../static/img/emojis/female-health-worker.png';
import emojiSwiss from '../../../../static/img/emojis/flag-switzerland.png';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Card from '@components/Card/Card';
import CardGallery from '@components/CardGallery/CardGallery';
import FatNumber from '@components/FatNumber/FatNumber';
import VideoBackgroundWithContent from '@components/VideoBackgroundWithContent';
// Modules
import Footer from '@components/Footer/Footer';
import HeaderMinimal from '@components/HeaderMinimal/HeaderMinimal';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import ProvidersList from '@components/ProvidersList/ProvidersList';
import Seo from '@components/seo';
import SideSection from '@components/SideSection/SideSection';
import { useLocale } from '@hooks';
import { withLocale, withTracking } from '@hocs';
import { useTracking } from '@hooks';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "providers/header-all-providers-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "providers/header-all-providers-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1420) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        feature3: file(relativePath: { eq: "home/home-feature4.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 455) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

const ProvidersPage = ({ data: graphData }) => {
    const { t } = useTranslation();
    const { getAppLinkWithCurrentLang } = useLocale();
    const { trackPageView } = useTracking();
    useEffect(() => {
        trackPageView();
    }, []);
    const headerImageSources = [
        graphData.headerMobileImage.childImageSharp.fluid,
        {
            ...graphData.headerDesktopImage.childImageSharp.fluid,
            media: `(min-width: 750px)`,
        },
    ];

    return (
        <Layout>
            <Seo
                title={t('providers.seo.title')}
                description={t('providers.seo.description')}
            />
            <div className="global_theme-green">
                <HeaderNav theme="white" />
                <Section theme="light">
                    <HeaderMinimal
                        theme="light"
                        title={t('providers.header.title')}
                        btnPrimary={
                            <AnchorLink offset={() => 40} href="#mehr">
                                <Button
                                    variant="primary"
                                    theme="white"
                                    label={t('button.get.started')}
                                    size="l"
                                />
                            </AnchorLink>
                        }
                        imageSrc={headerImageSources}
                    />
                </Section>

                {/* 3 Intro Cards */}
                <div id="mehr">
                    <Section theme="light" container="large" spacingBottom="xs">
                        <CardGallery size="3" layout="cascade">
                            <Card
                                format="v"
                                variant="heroTitle"
                                visual={<FatNumber emoji={emojiMonocle} />}
                                title={t('providers.intro.card1.title')}
                                text={t('providers.intro.card1.text')}
                            />

                            <Card
                                format="v"
                                variant="heroTitle"
                                visual={<FatNumber emoji={emojiDoctor} />}
                                title={t('providers.intro.card2.title')}
                                text={t('providers.intro.card2.text')}
                            />
                            <Card
                                format="v"
                                variant="heroTitle"
                                visual={<FatNumber emoji={emojiSwiss} />}
                                title={t('providers.intro.card3.title')}
                                text={t('providers.intro.card3.text')}
                            />
                        </CardGallery>
                    </Section>
                </div>

                {/* Providers */}
                <div id="providers">
                    <Section spacingBottom="l" spacingTop="m">
                        <Section container="short" spacingBottom="s">
                            <Title align="center" size="xxl" font="alt">
                                {t('providers.all.providers.section.title')}
                            </Title>

                            <div className="g_1_4 g_center">
                                <Divider spacing="m" />
                            </div>

                            <div className="g_2_3 g_center">
                                <Text align="center">
                                    {t(
                                        'providers.all.providers.section.subtitle'
                                    )}
                                </Text>
                            </div>
                        </Section>

                        {/* Cards */}
                        <Section container="large" spacingTop="s">
                            <ProvidersList size="200" />
                        </Section>

                        <Section container="large" spacingTop="m">
                            <div className="g_center">
                                <a
                                    href={getAppLinkWithCurrentLang(
                                        process.env.GATSBY_START_FULL_FUNNEL_URL
                                    )}
                                >
                                    <Button
                                        size="l"
                                        label={t('button.get.started')}
                                        iconArrow
                                    />
                                </a>
                            </div>
                        </Section>
                    </Section>
                </div>

                {/* We work with the best */}
                <Section container="large" theme="white" spacing="m">
                    <SideSection
                        title={t('providers.work.with.the.best.section.title')}
                        text={t('providers.work.with.the.best.section.text')}
                        link={
                            <>
                                <div>
                                    <Link to="/approach/">
                                        <Button
                                            variant="inline"
                                            theme="highlighted"
                                            label={t('nav.label.approach')}
                                            iconArrow
                                        />
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/about/">
                                        <Button
                                            variant="inline"
                                            theme="highlighted"
                                            label={t('nav.label.about.us')}
                                            iconArrow
                                        />
                                    </Link>
                                </div>
                            </>
                        }
                        visual={
                            <Img
                                fluid={graphData.feature3.childImageSharp.fluid}
                                alt="Header Image"
                            />
                        }
                    />
                </Section>

                <Section theme="highlighted" spacing="m">
                    <Section container="large">
                        <Title
                            size="xxl"
                            theme="white"
                            align="center"
                            font="alt"
                        >
                            <Trans
                                i18nKey={'banner.join.as.psychologist.title'}
                                components={{ br: <br /> }}
                            />
                        </Title>
                        <div>
                            <Link to="/for-therapists">
                                <Button
                                    theme="white"
                                    align="center"
                                    label={t('nav.label.for.psychologist')}
                                    isMobileFullsize
                                />
                            </Link>
                        </div>
                    </Section>
                </Section>

                <Section container="large" spacing="m">
                    <VideoBackgroundWithContent />
                </Section>

                <Footer />
            </div>
        </Layout>
    );
};

export default withTracking(withLocale(ProvidersPage));
